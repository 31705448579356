import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48')
];

export const server_loads = [0,2,8];

export const dictionary = {
		"/": [~11],
		"/auth/password-reset": [~46],
		"/auth/sign-in-sso": [~47],
		"/(app)/corp": [~12,[2]],
		"/(app)/home": [13,[2,3]],
		"/(app)/inbox": [14,[2,4]],
		"/(app)/inbox/assigned": [15,[2,4]],
		"/(app)/inbox/reviewing": [16,[2,4]],
		"/(app)/kb/overview": [17,[2,5]],
		"/(app)/kb/qa": [~18,[2,5]],
		"/(app)/kb/qa/import-project/[projectId=uuid]": [20,[2,5]],
		"/(app)/kb/qa/import": [~19,[2,5]],
		"/(app)/kb/resources": [~21,[2,5]],
		"/(app)/kb/runs/custom/[id=uuid]": [~22,[2,5]],
		"/(app)/kb/runs/refresh": [~23,[2,5]],
		"/(app)/kb/sources": [24,[2,5]],
		"/(app)/kb/sources/[sourceId=uuid]": [25,[2,5,6]],
		"/(app)/kb/sources/[sourceId=uuid]/entries/[entryId=uuid]": [26,[2,5,6]],
		"/nux": [~48],
		"/(app)/projects": [27,[2,7]],
		"/(app)/projects/new": [~36,[2,7]],
		"/(app)/projects/[id=uuid]": [28,[2,7,8]],
		"/(app)/projects/[id=uuid]/delete": [~29,[2,7,8]],
		"/(app)/projects/[id=uuid]/download": [~30,[2,7,8]],
		"/(app)/projects/[id=uuid]/edit": [~31,[2,7,8]],
		"/(app)/projects/[id=uuid]/export": [~32,[2,7,8]],
		"/(app)/projects/[id=uuid]/extract": [33,[2,7,8]],
		"/(app)/projects/[id=uuid]/question/[questionId=uuid]/answer/[answerId=uuid]": [~34,[2,7,8]],
		"/(app)/projects/[id=uuid]/sections": [35,[2,7,8,9]],
		"/(app)/settings": [37,[2,10]],
		"/(app)/settings/autogeneration": [~38,[2,10]],
		"/(app)/settings/integrations": [39,[2,10]],
		"/(app)/settings/members": [40,[2,10]],
		"/(app)/settings/tags": [~41,[2,10]],
		"/(app)/settings/teams": [42,[2,10]],
		"/(app)/settings/user/integrations": [43,[2,10]],
		"/(app)/settings/user/notifications": [~44,[2,10]],
		"/(app)/settings/user/profile": [~45,[2,10]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';